.contact {
    width: 100%;
    display: flex;
    margin-left: 20px;
    
    .header {
      color: #fff;
      font-weight: 700;
      font-size: 18px;
    }
    .text {
      margin-top: 50px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      width: 270px;
      margin: 0 auto;
    }
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }  
  .contactInfo {
    margin-top: 50px;
  }
  
  .support {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 10px;
  }
  
  .group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .number {
    font-weight: 700;
    font-size: 16px;
    color: #7c7b7b;
    margin: 0;
  }
  
  .call {
    font-size: 15px;
    color: #7c7b7b;
    margin: 0;
  }
  
  .iconCon {
    background-color: #0093dd;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .copyButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 5px;
  }
  