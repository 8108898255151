.submitted-sheet-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.submitted-sheet {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

strong{
  color: #0093dd;
}
h3 {
  text-align: center;
  color: #3a3a3a;

}

.sheet-details {
  margin-top: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.row p {
  margin: 0;
}

.punctuality-section {
  background-color: #F4F4F4;
  margin-top: 20px;
  padding: 20px;
}
.attributes{
  padding: 10px;
  display: flex;
  gap: 30px;
}
.attributes span{
  font-weight: 700;
}

.signature-section {
  text-align: center;
}

.sigcontainer{
  background-color: #fff
}
.signature {
  width: 150px;
  height: auto;
  margin-top: 10px;
}

.submission-info {
  color: #0093dd;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}
