
  .header {
    font-size: 18px;
    font-weight: 700;
    color: #3a3a3a;
    margin-bottom: 20px;
  }
  
  .detailContainer {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #7c7b7b;
    padding: 10px 20px;
  }
  .detail{
    margin-bottom: 10px;
  }
  
  .desc {
    color: #7c7b7b;
    font-size: 16px;
    margin: 0;
    font-weight: normal;
  }
  
  .object {
    color: #7c7b7b;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  
  .passwordCon {
    background-color: #f4f4f4;
    border-radius: 8px;
  }
  
  .input {
    background-color: #fff;
    color: #7c7b7b;
    font-weight: 700;
    padding: 10px 15px;
    margin-bottom: 10px;
    margin: 10px;
    border-radius: 10px;
    border: none;
    width: calc(100% - 60px);
  }
  
  .btn {
    background-color: #0093dd;
    padding: 20px 30px;
    border-radius: 20px;
    margin-top: 15px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    border: none;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  