.schedule {
    .header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #fff;
    }
    .dateSelector p {
      color: #fff;
      margin-bottom: 20px;
    }
    .modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .modalContent {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    text-align: center;
    }
}
.modalTitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .timeSlot {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .selectedTimeSlot {
    background-color: #d3d3d3;
  }
  
  .colorBox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .timeSlotText {
    font-size: 16px;
    text-transform: capitalize;
  }
  
  .saveButton {
    margin-top: 20px;
    background-color: #0093DD;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    border: none;
  }
  .day-selection-modal label{
    color: #fff;
  }