.login-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin-left: -10px;
  }
  
  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    margin-top: 60px;
  }
  
  .form-container {
    margin-top: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .text-input {
    height: 70px;
    border: 1px solid #0093DD;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  
  .login-button {
    height: 70px;
    background-color: #0093DD;
    border-radius: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #0093dd;
  }
  