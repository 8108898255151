.contract {
    padding: 25px;
    background-color: #eaf7fd !important;
    .header {
    font-size: 24px;
    font-weight: 700;
    color: #3a3a3a;
  }
  .divider {
    border-top: 5px solid #0093dd;
    width: 55%;
    margin-top: 20px;
  }
}
  
  .imgCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
  
  
  
  .note {
    color: #a1a1a1;
    font-size: 17px;
    font-weight: 600;
    margin-top: 30px;
    padding-left: 25px;
  }
  
  .button {
    background-color: #0093dd;
    padding: 20px 30px;
    border-radius: 20px;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    margin-top: 50px;
    border: none;
    cursor: pointer;
  }
  
  .textCon {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
  }
  
  .signedText {
    font-weight: 700;
    font-size: 18px;
  }
  
  .uploadButton {
    font-weight: 700;
    font-size: 18px;
    color: #0093dd;
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
  }
  