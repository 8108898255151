.ts-history {
    background-color: #E1E2E4 !important;
    padding: 15px;
.attendance {
    display: flex;
    gap: 10px;
    margin-top: 30px;
  }}
  .attendanceBox1, .attendanceBox2 {
    width: 95px;
    border-radius: 10px;
    padding: 10px;
  }
  
  .attendanceBox1 {
    background-color: #0093DD;
  }
  
  .attendanceBox2 {
    background-color: #fff;
    border: 1px solid #0093DD;
  }
  
  .text1 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .text2 {
    color: #0093DD;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .small {
    font-size: 12px;
    margin: 0;
  }
  
  .large {
    font-size: 18px;
    margin: 0;
  }
  
  .whiteText {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }
  .blueText {
    color: #0093DD;
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }
  
  .viewHistory {
    margin-top: 15px;
    font-weight: bold;
    font-size: 20px;
    color: #0093DD;
  }
  
  .dropdown {
    background-color: #fff;
    border-radius: 5px;
    margin-top: 15px;
    width: 160px;
    align-self: flex-end;
  }
  
  .historyContainer {

  }
  
  .history {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .group {
    display: flex;
    gap: 20px;
  }
  
  .iconContainer {
    background-color: #0093DD;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .details{
    font-family: 'Nunito', sans-serif;
    margin-top: 10px;
  }
  .hour {
    font-weight: bold;
    font-size: 18px;
    margin: 0;
  }
  
  .date {
    font-weight: bold;
    font-size: 14px;
    color: #3A3A3A;
    margin: 0;
  }
  
  .arrow {
    margin-top: 10px;
  }
  
  .divider {
    border: 1px solid #fff;
    margin-top: 7px;
  }
  .dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}
  
.attendance-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.attendance-table th, .attendance-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.attendance-table th {
  background-color: #f2f2f2;
}

.attendance-table tr:nth-child(even) {
  background-color: #f9f9f9;
}