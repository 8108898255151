.upload{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center
}
.uploadBox{
    border: dotted 4px #c4c4c4;
    padding: 30px;
    width: 50px;
    display: flex;
    justify-content: center,
}
.upload-text{
    color: #5A5A5A;
    font-weight: 700; 
    font-size: 18px; 
    margin-top: 20px;
}