@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body{
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 100px;
  width: 95vw;
}
h4{
  font-family: 'Montserrat', sans-serif;
}
.header1{
  font-size: 18px;
  font-weight: 700;
  color: #1F1F1F;
}
.header2{
  font-size: 18px;
  font-weight: 700;
  color: #3A3A3A;
  margin-top: 60px;
}
.btn{
  background-color: #0093DD;
  padding: 15px 30px;
  border-radius: 20px;
  margin-top: 15px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border: none;
  width: 100%;
}